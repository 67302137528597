import React from "react";
import {colors} from "../colors";
import Container from "../grid/container";
import {breakpoints} from "../tokens";

interface PaginationProps {
    pages: number
    currentPage: number
    onChange: (number: number) => void
}

const Pagination = ({ pages, currentPage, onChange }: PaginationProps) => {


    return (
        <Container css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            marginTop: '84px',
            marginBottom: '84px',
            [breakpoints.tb]: {
                marginTop: '64px',
                marginBottom: '64px',
            }
        }}>
            {pages > 0 && (
                <button
                    type="button"
                    css={{
                        backgroundColor: colors.shadesWhite,
                        boxShadow: '2px 2px 7px #0001',
                        width: '36px',
                        height: '36px',
                        flex: 'none',
                        color: colors.shadesBlack,
                        borderRadius: '500px',
                        transition: '0.2s',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '&:disabled': {
                            backgroundColor: colors.neutral1,
                            boxShadow: '2px 2px 7px #0000',
                            color: colors.neutral5,
                        },
                        '&:hover:not(:disabled)': {
                            backgroundColor: colors.neutral2,
                        }
                    }}
                    disabled={currentPage === 1 || pages < 2}
                    onClick={(event) => {
                        event.preventDefault();
                        onChange(currentPage - 2);
                    }}
                >
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.89961 10.8002L1.09961 6.0002L5.89961 1.2002" stroke="currentColor" strokeWidth="0.8" strokeLinecap="square" strokeLinejoin="round"/>
                    </svg>
                </button>
            )}
            {new Array(pages).fill(null).map((_, index) => {
                const page = index + 1;
                return (
                    <button
                        role="button"
                        data-active={page === currentPage}
                        css={{
                            backgroundColor: colors.shadesWhite,
                            width: '36px',
                            height: '36px',
                            flex: 'none',
                            color: colors.shadesBlack,
                            borderRadius: '500px',
                            transition: '0.2s',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&[data-active=true]': {
                                backgroundColor: colors.primaryOrange,
                                color: colors.shadesWhite,
                            },
                            '&:hover:not([data-active=true])': {
                                backgroundColor: colors.neutral2,
                            }
                        }}
                        onClick={(event) => {
                            event.preventDefault();
                            onChange(index);
                        }}
                    >
                        {page}
                    </button>
                )
            }).slice(Math.max(currentPage - 2, 0), Math.max(currentPage - 2, 0) + 3)}
            {pages > 0 && (
                <button
                    type="button"
                    disabled={currentPage === pages}
                    css={{
                        backgroundColor: colors.shadesWhite,
                        boxShadow: '2px 2px 7px #0001',
                        width: '36px',
                        height: '36px',
                        flex: 'none',
                        color: colors.shadesBlack,
                        borderRadius: '500px',
                        transition: '0.2s',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '&:disabled': {
                            backgroundColor: colors.neutral1,
                            boxShadow: '2px 2px 7px #0000',
                            color: colors.neutral5
                        },
                        '&:hover:not(:disabled)': {
                            backgroundColor: colors.neutral2,
                        }
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                        onChange(currentPage);
                    }}
                >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 13L7.5 7L1.5 1" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round"/>
                    </svg>
                </button>
            )}
        </Container>
    )
}

export default Pagination
